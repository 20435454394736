import React from 'react';
import "./inspector.css";

const SessionSummery = ({summery, frameSizeAvg, videoDimensions}) => {
    delete summery["OTSessionId"]
    delete summery["exerciseConfig"]
    let data = summery;
    data.avgFrameSize = (Math.round(frameSizeAvg/1000)).toLocaleString() + "Kb";
    data.videoDimensions = videoDimensions?.width + "x" + videoDimensions?.height;
    formatDate();
    countReps();

    data = convertCamelCaseKeysToSpaces(data);

    const tableStyle = {
        letterSpacing: '0.4px',
        borderCollapse: 'collapse',
        width: '100%',
    };
    const thStyle = {
        fontSize: '12px',
        borderBottom: '1px solid #e9e9e9',
        padding: '4px',
        textAlign: 'left',
        paddingRight: '9px',
    };

    const tdStyle = {
        padding: '8px',
        fontSize: '10px',
    };

    function formatDate() {
        if (!data?.startTime?.value) {
            return;
        }
        const dateObject = new Date(data.startTime.value);
        data.startTime = new Intl.DateTimeFormat('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Use 24-hour format
            separator: '/',
        }).format(dateObject)
    }
    function countReps() {
        const reps = data?.reps;
        let counts
        if (!reps) {
            counts = null
        }
        const countE = reps ? reps.split(',').filter(item => item === 'E').length : 0;
        const countG = reps ? reps.split(',').filter(item => item === 'G').length : 0;
        counts = `${countG}  |  ${countE}  |  ${countE + countG}`
        const countsKey = "G   |  E  |  Total"
        const outputObject = {
            ...data,
            [countsKey]: counts
        };

        // Rearrange keys to place the new key after "reps"
        const keys = Object.keys(outputObject);
        const indexOfReps = keys.indexOf("reps");
        keys.splice(indexOfReps + 1, 0, countsKey); // Insert "repCounts" key

        const rearrangedObject = {};
        keys.forEach(key => {
            rearrangedObject[key] = outputObject[key];
        });
        data = rearrangedObject;
    }
    function camelCaseToSpaces(str) {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    function convertCamelCaseKeysToSpaces(obj) {
        const newObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const spacedKey = camelCaseToSpaces(key);
                newObj[spacedKey] = obj[key];
            }
        }
        return newObj;
    }

    return (
        <div className={"table-wrapper"}>
            <table style={tableStyle}>
                <tbody>
                <tr>
                    {Object.keys(data).map((key) => (
                        <th key={key} style={thStyle}>{key}</th>
                    ))}
                </tr>
                <tr>
                    {Object.values(data).map((value, index) => (
                        <td key={index} style={tdStyle}>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
                    ))}
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SessionSummery;
