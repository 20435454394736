import {useState, useEffect, useRef} from "react";

export const useWebSocket = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [sessionId, setSessionId] = useState(
        // Math.round(1990000000 + Math.random() * 10000).toString()
    );
    const [responseCounts, setResponseCounts] = useState({ good: 0, bad: 0 });


    const ws = useRef(null);

    const connect = (moveAiSessionId) => {
        setSessionId(moveAiSessionId)
        const url = `wss://x4nrdqjg6d.execute-api.us-east-1.amazonaws.com/dev?sessionId=${moveAiSessionId}`;
        if (ws.current) {
            return;
        }

        ws.current = new WebSocket(url);
        ws.current.onopen = () => {
            console.log("%c========connected=========", "color: green; font-weight: bold;");
            setIsConnected(true);
        };

        ws.current.onmessage = (event) => {
            if (event.data) {
                let response = event.data;
                if (response.includes("Excellent Rep!")) {
                    console.log("%cGOOD", "color: green; font-weight: bold;");
                    setResponseCounts(prevState => ({ ...prevState, good: prevState.good + 1 }));
                } else if (response.includes("Bad Rep")) {
                    console.log("%cBAD", "color: red; font-weight: bold;");
                    setResponseCounts(prevState => ({ ...prevState, bad: prevState.bad + 1 }));
                }
            }
        };

        ws.current.onclose = () => {
            console.log("%c========closed=========", "color: orange; font-weight: bold;");
            setIsConnected(false);
        };
        ws.current.onerror = (error) => {
            console.log("%c========error=========", "color: red; font-weight: bold;");
            setIsConnected(false);
            console.log(error);
        };
    };

    const handleSessionIdChange = (event) => {
        setSessionId(event.target.value);
    };

    useEffect(() => {
        return () => {
            if (ws.current) {
                console.log("use effect closed");
                ws.current.close();
                ws.current = null;
            }
        };
    }, []);

    return {ws, isConnected, connect, handleSessionIdChange, sessionId, responseCounts};
};
