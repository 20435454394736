import React from 'react';
import "./inspector.css";
const AnglesBox = ({frame}) => {

    if (!frame) {
        frame =[];
    }
    const angles = frame.map((landmark) => {
            if (landmark.angle) {
                return [landmark.joint, landmark.angle];
            }
        })
        .filter((entry) => entry !== undefined);

    return (
        <>
            <div className="angles-box">
                <div className="box-header">
                    <h3>Angles</h3>
                </div>


                <div className={"box-content"}>
                    {angles.map((angle, key) => (
                        <div className={"angle-pair"} key={key}>
                          {angle[0]}: {angle[1]}°
                        </div>
                    ))}</div>
            </div>
        </>
    );
};

export default AnglesBox;
