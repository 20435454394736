const ENV = "dev" //will be replaced by dockerfile on build

export const isDev = (ENV === 'dev' || ENV === '@@##@@') //for local
const domain = isDev ? "wizecaredev.com" : "wizecare.com";
const projectId = isDev ? "wize-development-342714" : "wizecare-2021"

export default {
    OT_API_KEY: isDev ? '45685722' : '45685722',//TODO: prod
    GET_TRACE_DATA_FUNC_URL: `https://europe-west1-${projectId}.cloudfunctions.net/get-maas-trace-data`,
    PROJECT_ID: projectId,
    serverUrl:  `https://api.${domain}/vt/api/dispatcher.php`,
}
