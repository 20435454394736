import firebase from "../firebase";
import axios from "axios";
import resources from "./resources";
import utils from "./utils";

const callServer = async (params) => {
    try {
        const api = params.api.split('/')[1];
        const token = await firebase.getIdToken()
        const response = await axios.post(resources.serverUrl + "?" + api, params,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        response.data.data.logsLink =  response?.data?.logEntries
        return response?.data?.data
    } catch (error) {
        console.log(error);
    }
}
export default {

    launchMaas : async (exerciseId, invokeDealer) => {
        const params = {
            api: 'exercises/launch-maas',
            exerciseId,
            invokeDealer:invokeDealer
        };
        return callServer(params);
    },

     getExcercises : async () => {
        const params = {
            api: 'exercises/get-exercises',
        };
         const exercises = await callServer(params);
         return utils.filterByIsMoveAICompatible(exercises);
    },

    getMoveAiSessionDataBySessionId : async (moveAiSessionId) => {
        const params = {
            api: 'moveai/get-move-ai-session-data-by-session-id',
            moveAiSessionId,
        };
        return callServer(params);
    },

    getLastMoveAiSessions : async () => {
        const params = {
            api: 'moveai/get-last-move-ai-sessions',
            numOfSessions:'15'
        };
        return callServer(params);
    },
    getConfig : async () => {
        const params = {
            api: 'moveai/get-config',
        };
        return callServer(params);
    },

}