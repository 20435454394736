import React from 'react';

export const PlayButton = () => (
    React.createElement('svg', { fill: '#000000', version: '1.1', width: '15px', height: '15px', viewBox: '0 0 163.861 163.861', xmlSpace: 'preserve' },
        React.createElement('g', null,
            React.createElement('path', { d: 'M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z' })
        )
    )
);

export const PauseButton = () => (
    React.createElement('svg', { fill: '#000000', width: '15px', height: '15px', viewBox: '0 0 32 32'},
        React.createElement('path', { d: 'M5.92 24.096q0 0.832 0.576 1.408t1.44 0.608h4.032q0.832 0 1.44-0.608t0.576-1.408v-16.16q0-0.832-0.576-1.44t-1.44-0.576h-4.032q-0.832 0-1.44 0.576t-0.576 1.44v16.16zM18.016 24.096q0 0.832 0.608 1.408t1.408 0.608h4.032q0.832 0 1.44-0.608t0.576-1.408v-16.16q0-0.832-0.576-1.44t-1.44-0.576h-4.032q-0.832 0-1.408 0.576t-0.608 1.44v16.16z' })
    )
);

export const NextButton = () => (
    React.createElement('svg', { width: '15px', height: '15px', viewBox: '0 -2 12 12'},
        React.createElement('g', { id: 'Page-1', stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
            React.createElement('g', { id: 'Dribbble-Light-Preview', transform: 'translate(-144.000000, -3805.000000)', fill: '#000000' },
                React.createElement('g', { id: 'icons', transform: 'translate(56.000000, 160.000000)' },
                    React.createElement('path', { d: 'M99.684,3649.69353 L95.207,3652.82453 C94.571,3653.25353 94,3652.84553 94,3652.13153 L94,3650.14053 L89.78,3652.82453 C89.145,3653.25353 88,3652.84553 88,3652.13153 L88,3645.86853 C88,3645.15453 89.145,3644.74653 89.78,3645.17453 L94,3647.85953 L94,3645.86853 C94,3645.15453 94.571,3644.74653 95.207,3645.17453 L99.516,3648.30653 C100.03,3648.65353 100.198,3649.34653 99.684,3649.69353' })
                )
            )
        )
    )
);
export const PreviousButton = () => (
    React.createElement('svg', { width: '15px', height: '15px', viewBox: '0 -2 12 12'},
        React.createElement('g', { id: 'Page-1', stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
            React.createElement('g', { id: 'Dribbble-Light-Preview', transform: 'translate(-104.000000, -3805.000000)', fill: '#000000' },
                React.createElement('g', { id: 'icons', transform: 'translate(56.000000, 160.000000)' },
                    React.createElement('path', { d: 'M59.9990013,3645.86816 L59.9990013,3652.13116 C59.9990013,3652.84516 58.854013,3653.25316 58.2180013,3652.8216 L53.9990013,3650.14016 L53.9990013,3652.13116 C53.9990013,3652.84516 53.4260013,3653.25316 52.7900013,3652.82516 L48.4790013,3649.69316 C47.9650013,3649.34616 47.7980013,3648.65316 48.3120013,3648.30616 L52.7900013,3645.17516 C53.4260013,3644.74616 53.9990013,3645.15416 53.9990013,3645.86816 L53.9990013,3647.85916 L58.2180013,3645.17516 C58.8540013,3644.74616 59.9990013,3645.15416 59.9990013,3645.86816' })
                )
            )
        )
    )
);
