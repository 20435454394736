import React from "react";
import {Button, Grid, TextField} from "@mui/material";
import DataModal from "../Main/DataModal";

function LabTools({handleSessionIdChange, sessionId, isConnected, responseCounts}) {
    function ResetButton() {
        window.location.reload();
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                        <p className="good-rep-p">Good responses: {responseCounts.good}</p>
                    </Grid>
                    <Grid item>
                        <p className="bad-rep-p">Bad responses: {responseCounts.bad}</p>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={ResetButton}>
                            Reset
                        </Button>
                    </Grid>
                    <Grid item>
                        <DataModal sessionId={sessionId}/>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="outlined-basic"
                            label="SessionID"
                            variant="outlined"
                            value={sessionId}
                            onChange={handleSessionIdChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            InputProps={{readOnly: true}}
                            label="Server Connection"
                            value={isConnected ? "Connected" : "Disconnected"}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LabTools;
