import React, {useEffect, useRef, useState} from "react";

function CanvasDots({dots, showAngles}) {

    const canvasRef = useRef(null);
    const bones = [
        ['L_shoulder', 'R_shoulder'],
        ['L_shoulder', 'L_elbow'],
        ['L_elbow', 'L_wrist'],
        ['R_shoulder', 'R_elbow'],
        ['R_elbow', 'R_wrist'],
        ['L_shoulder', 'L_hip'],
        ['R_shoulder', 'R_hip'],
        ['L_hip', 'R_hip'],
        ['L_hip', 'L_knee'],
        ['R_hip', 'R_knee'],
        ['L_knee', 'L_ankle'],
        ['R_knee', 'R_ankle'],
        ['L_ankle', 'L_heel'],
        ['R_ankle', 'R_heel'],
        ['L_heel', 'L_toe'],
        ['R_heel', 'R_toe'],
    ];


    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        function resizeCanvas() {
            if (!dots) return
            const sibling = canvas.previousElementSibling;

            // Set the canvas size based on the sibling video element's dimensions
            canvas.width = sibling.clientWidth || 170;
            canvas.height = sibling.clientHeight || 300;
            drawCanvas();
        }

        function drawCanvas() {

            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // Draw bone lines
            bones.forEach((bone) => {
                drawBoneLine(bone[0], bone[1]);
            });
            // Draw dots
            dots.forEach((dot) => {
                ctx.beginPath();

                ctx.fillStyle = "#41beb3";
                // Draw the circle for other joints
                let radius = 4;
                // if joint is on face, make the radius smaller
                if (dot['joint'] === 'L_eye' || dot['joint'] === 'R_eye' || dot['joint'] === 'L_ear' || dot['joint'] === 'R_ear' || dot['joint'] === 'nose') {
                    radius = 2;
                }
                ctx.arc(dot.x * canvas.width, dot.y * canvas.height, radius, 0, 2 * Math.PI);
                ctx.fill();
                if (dot['angle'] && showAngles) {
                    ctx.fillStyle = "white";  // Set the background color to white
                    ctx.fillRect(dot.x * canvas.width - 8, dot.y * canvas.height - 14, dot['angle'].toString().length*8, 14);  // Adjust the position and size of the background
                    ctx.fillStyle = "#020202";
                    ctx.font = 'bold 12px Arial';
                    ctx.fillText(dot['angle'], dot.x * canvas.width - 6, dot.y * canvas.height - 3);
                }
                if (dot['joint'].includes('hip')) {
                    ctx.fillStyle = "#000000";
                    ctx.font = '8px Arial';
                    let side = dot['joint'].charAt(0);
                    ctx.fillText(side, (dot.x * canvas.width)-3, (dot.y * canvas.height)+4);
                }

            });

            function drawBoneLine(index1, index2) {
                ctx.strokeStyle = "#cacaca";
                ctx.lineWidth = 2;
                const dot1 = dots.find((dot) => dot.joint === index1);
                const dot2 = dots.find((dot) => dot.joint === index2);
                if (!dot1 || !dot2) return;
                ctx.beginPath();
                ctx.moveTo(dot1.x * canvas.width, dot1.y * canvas.height);
                ctx.lineTo(dot2.x * canvas.width, dot2.y * canvas.height);
                ctx.stroke();
            }
        }

        // Add event listener for resizing the canvas when the window size changes
        window.addEventListener("resize", resizeCanvas);
        resizeCanvas();

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", resizeCanvas);
        };
    }, [dots]);

    return (
        <canvas className={"ot-canvas"} ref={canvasRef}/>
    );
}
CanvasDots.defaultProps = {
    showAngles: true
};
export default CanvasDots;