import React, { useState } from 'react';
import {
    Button,
    Modal,
    Backdrop,
    Fade,
    makeStyles,
    Switch,
    FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import apiCalls from '../tools/apiCalls';
import SessionGraph from '../tools/SessionGraph';
import MoveAiVisualizer from '../drawing/MoveAiVisualizer';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        position: 'relative',
        width: 1200,
        height: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
    header: {
        textAlign: 'center',
        margin: theme.spacing(0, 0, 2),
    },
    sessionGraphContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    visualizerContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    switchContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(2, 0),
    },
}));

const DataModal = ({ sessionId }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [res, setRes] = useState();
    const [isSessionGraph, setIsSessionGraph] = useState(true); // State for the toggle switch
    const handleOpen = () => {
        apiCalls
            .getMoveAiSessionDataBySessionId(sessionId)
            .then((response) => {
                setRes(response);
                setOpen(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setIsSessionGraph(!isSessionGraph);
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                More Info
            </Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Button className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                        <h2 className={classes.header}>Init Session</h2>
                        <div className={classes.switchContainer}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!isSessionGraph}
                                        onChange={handleToggle}
                                        name="toggle"
                                        color="primary"
                                    />
                                }
                                label="Show MoveAi Visualizer"
                                labelPlacement="start"
                            />
                        </div>
                        <div className={classes.sessionGraphContainer}>
                            {isSessionGraph ? (
                                <SessionGraph sessionId={sessionId} />
                            ) : null}
                        </div>
                        <div className={classes.visualizerContainer}>
                            {!isSessionGraph ? (
                                <MoveAiVisualizer formData={res} />
                            ) : null}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export default DataModal;
