import React, {useEffect, useState} from 'react';
import WorkflowSteps from "./workflowSteps";
import SessionSummery from "./sessionSummery";
import FramesSection from "./framesSection";
import GraphsSection from "./graphsSection";
import axios from "axios";
import {CircularProgress} from "@mui/material";
import {openDB} from 'idb';
import firebase from "../firebase";
import resources from "../tools/resources";

const InspectorTop = () => {
    const [data, setData] = useState(null)
    const [traceId, setTraceId] = useState(null);

    useEffect(() => {
    }, [data]);

    useEffect(() => {
        const getTraceIdFromQueryString = () => {
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString);
            return params.get('traceId');
        };

        const traceIdFromQuery = getTraceIdFromQueryString();
        setTraceId(traceIdFromQuery);
        fetchAndCacheTrace(traceIdFromQuery);
    }, []);

    function calculateAverageTimeDifferences(arr) {
        arr.sort((a, b) => a?.moveAiFrameNum ?? a.frameNumber - b?.moveAiFrameNum ?? b.frameNumber);
        const keysToCompare = [
            "dealingFrameToVision",
            "dealingPoseToClient",
            "dealingToApiGateway",
            "sendPoseToDealer",
            "sendToMP",
            "visionReceivedFrame",
        ];
        // Create an object to store the arrays of time differences for each key
        const avgTimes = {};

        for (let i = 0; i < arr.length - 1; i++) {
            const currentObj = arr[i];
            const nextObj = arr[i + 1];

            for (const key of keysToCompare) {
                if (currentObj.hasOwnProperty(key) &&
                    nextObj.hasOwnProperty(key) &&
                    nextObj.frameNumber - currentObj.frameNumber === 1 &&
                    nextObj[key] && currentObj[key]
                ) {
                    const timeDiff = nextObj[key] - currentObj[key];

                    if (!avgTimes[key]) {
                        avgTimes[key] = [];
                    }
                    avgTimes[key].push(timeDiff);
                }
            }
        }
        return avgTimes;
    }

    const getDb = openDB('trace-db', 1, {
        upgrade(db) {
            db.createObjectStore('traces');
        },
    });

    async function fetchAndCacheTrace(traceId) {
        const db = await getDb;
        let data = await db.get('traces', traceId);

        if (!data) {
            const token = await firebase.getIdToken()
            const url = `${resources.GET_TRACE_DATA_FUNC_URL}?traceId=${traceId}`
            const headers = {
                'Authorization': `Bearer ${token}`
            };
            const response = await axios.get(url, {headers});

            data = response.data;
            await db.put('traces', data, traceId);
        }

        setData(data);
    }

    async function clearCache() {
        const db = await getDb;
        await db.clear('traces');
    }

    if (!data) {
        return (
            <div className="loader">
                <CircularProgress/>
            </div>
        )
    }

    let framesArr = data.frames;
    const timingData = framesArr.map((frame) => {
        return frame.meta
    })
    const timesObject = calculateAverageTimeDifferences(timingData);
    const logsUrl = `https://console.cloud.google.com/logs/query;query=%0AjsonPayload.meta.traceId%3D%22${traceId}%22;summaryFields=jsonPayload%252FprocessName,jsonPayload%252FtraceId,jsonPayload%252FWizecareAccountId,jsonPayload%252FuserAgent%252Fplatform,jsonPayload%252Fmeta%252FprocessName,jsonPayload%252Fmeta%252FtraceId,jsonPayload%252Fmeta%252FWizecareAccountId,jsonPayload%252Fmeta%252FuserAgent%252Fplatform,jsonPayload%252Fmeta%252FmaasUnit:false:32:beginning;lfeCustomFields=jsonPayload%252Fmeta%252FprocessName,jsonPayload%252Fmeta%252FtraceId,jsonPayload%252Fmeta%252FWizecareAccountId,jsonPayload%252Fmeta%252FuserAgent%252Fplatform,jsonPayload%252Fmeta%252FprocessType;duration=PT24H?project=${resources.PROJECT_ID}`
    const OtInspectorUrl = `https://tokbox.com/developer/tools/inspector/account/3144902/project/${resources.OT_API_KEY}/session/${data["sessionSummery"][0]["OTSessionId"]}`
    const frameSizes = [];
    for (let i = 0; i < timingData.length - 1; i++) {
        frameSizes.push(timingData[i].size);
    }
    const frameSizeAvg = frameSizes.reduce((a, b) => a + b, 0) / frameSizes.length;


    function openOtInspector() {
        window.open(logsUrl, '_blank');
    }

    const workflowSteps = data?.workflowSteps;
    data.sessionSummery[0].imagesSaved = data?.imagesSaved
    const OTStep = workflowSteps.find((item) => item.step.includes("Connected to OT session"))
    const OTSessionData = JSON.parse(OTStep?.stepData || "{}");
    return (
        <>


            <div className={"inspector-top"}>

                <div className={"workflow-and-logs"}>
                    <div className={"logs-and-ot"}>
                        <button onClick={() => window.open(logsUrl, '_blank')} className={"logs-button"}>
                            Logs
                        </button>
                        <button onClick={() => window.open(OtInspectorUrl, '_blank')} className={"ot-button"}>
                            OT Inspector
                        </button>

                    </div>
                    <WorkflowSteps
                        steps={workflowSteps}
                    />
                </div>
                <div className={"summery-wrapper"}>
                    <button onClick={clearCache} className={"clr-cache-button"} title="Clear Cache">
                        <svg className="svg-icon"
                             style={{width: '15px', height: '15px', margin: '-1px'}}
                             viewBox="0 0 1024 1024" version="1.1">
                            <path
                                d="M987.428571 268.190476h-950.857142a36.571429 36.571429 0 0 1 0-73.142857H243.809524V73.142857a73.142857 73.142857 0 0 1 73.142857-73.142857h390.095238a73.142857 73.142857 0 0 1 73.142857 73.142857v121.904762h207.238095a36.571429 36.571429 0 0 1 0 73.142857zM707.047619 121.904762a48.761905 48.761905 0 0 0-48.761905-48.761905H365.714286a48.761905 48.761905 0 0 0-48.761905 48.761905v73.142857h390.095238V121.904762zM341.333333 816.761905v-390.095238a36.571429 36.571429 0 0 1 73.142857 0v390.095238a36.571429 36.571429 0 0 1-73.142857 0z m268.190477 0v-390.095238a36.571429 36.571429 0 0 1 73.142857 0v390.095238a36.571429 36.571429 0 0 1-73.142857 0zM158.47619 316.952381a36.254476 36.254476 0 0 1 34.133334 24.380952H195.047619v560.761905a48.761905 48.761905 0 0 0 48.761905 48.761905h536.380952a48.761905 48.761905 0 0 0 48.761905-48.761905V341.333333h2.438095a36.059429 36.059429 0 0 1 68.266667 0h2.438095v609.52381a73.142857 73.142857 0 0 1-73.142857 73.142857H195.047619a73.142857 73.142857 0 0 1-73.142857-73.142857V341.333333h2.438095a36.254476 36.254476 0 0 1 34.133333-24.380952z"/>
                        </svg>
                    </button>
                    <SessionSummery
                        summery={data.sessionSummery[0]}
                        frameSizeAvg={frameSizeAvg}
                        videoDimensions={OTSessionData?.stream?.videoDimensions}
                    />
                </div>
                <GraphsSection
                    timingData={timesObject}
                    moveAiSessionIds={data.sessionSummery[0].moveAiSessionIds}
                />
                <FramesSection
                    framesData={data}
                    traceId={traceId}
                    zipUrl={data.imagesZipUrl}
                />


            </div>

        </>

    );
}
export default InspectorTop;