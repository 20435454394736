import React, {useEffect, useRef, useState} from "react";
// components
import {makeStyles} from "@material-ui/core";
import {
    CircularProgress,
    createTheme,
    ThemeProvider
} from "@mui/material";
import {green} from '@material-ui/core/colors';

// ready to use API (https://google.github.io/mediapipe/getting_started/javascript.html#ready-to-use-javascript-solutions)
import MainCanvas from "../drawing/MainCanvas";
import {useWebSocket} from "../tools/useWebScokets";
import LabTools from "../tools/LabTools";
import utils from "../tools/utils";

const useStyles = makeStyles((theme) => ({
    main: {
        opacity: (loader) => (loader ? 0 : 1),
        transition: "0.5s",
    },
    loader: {
        margin: "64px auto",
    },
    loaderContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
    },
    buttonGroup: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: "10px",
    },
    browseButton: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: "10px",
        left: "350px"
    },
    greenButton: {
        margin: "10px !important",
    }
}));

const theme = createTheme({
    palette: {
        primary: green,
    },
});


function FrameFileToCore(formData) {

    const {ws, isConnected, connect, handleSessionIdChange, sessionId, responseCounts} = useWebSocket();
    //video needs
    const canvasRef = useRef(null);
    const [poseLandmarksState, setPoseLandmarksState] = useState(undefined);
    //loader boolean
    const [loader, setLoader] = useState(true);
    const classes = useStyles(loader);
    const [uploadedData, setUploadedData] = useState(null);

    //waiting for function and gets parameter
    const frameNumberRef = useRef(0);
    const framesSentRef = useRef(0); // New ref to store the number of frames sent

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(event) {
                try {
                    const json = JSON.parse(event.target.result);
                    setUploadedData(json);
                } catch (error) {
                    console.error('Invalid JSON:', error);
                }
            };
            reader.readAsText(file);
        }
    };

    useEffect(() => {
        uploadedData && sendFrames(uploadedData);  // Pass uploadedData instead of hardcoded JSON
    }, [uploadedData]);

    useEffect(() => {
        // handleLambdaConnect()
        connect(formData?.formData?.moveAiSessionId);
        setLoader(false)
    }, [])

    let frameInQueue
    let frameIndex = 0


    const sendFrames = async (data) => {
        if (data) {
            frameInQueue = utils.reorderArrayFunc(utils.normalizeAspectRatioFunc(Object.values(data[frameIndex] || {})));
            sendFrameToCore();
            setPoseLandmarksState(data[frameIndex])
            frameIndex++

            if (frameIndex > data.length) return
        }
        await new Promise((resolve) => setTimeout(resolve, 33));
        await sendFrames(data)
    }

    const sendFrameToCore = () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN && frameInQueue) {
            frameNumberRef.current += 1;
            framesSentRef.current += 1;
            let transformedLandmarks = frameInQueue + "," + frameNumberRef.current;
            console.log(transformedLandmarks)
            ws.current.send(
                JSON.stringify({
                    action: "saveMessageFrame",
                    data: {sessionId: `${formData?.formData?.moveAiSessionId}`, frames: transformedLandmarks},
                })
            );
        }
    };


    const img = new Image();
    img.src = "https://img.freepik.com/vecteurs-premium/surface-blanche-rayures-rondes-couverture-souple-blanche_547648-928.jpg"

    return (
        <>
            <div>
                <div className={classes.browseButton}>
                    <input type="file" accept=".json" onChange={handleFileUpload} />
                </div>
            </div>
            <ThemeProvider theme={theme}>
                {loader && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress className={classes.loader} size={300}/>
                    </div>
                )}
                <div className={classes.main}>
                    <MainCanvas
                        canvasRef={canvasRef}
                        poseLandmarks={{poseLandmarks: poseLandmarksState}}
                        img={img}
                    />
                </div>
                <div>
                    <div className={classes.buttonGroup}>
                        <LabTools
                            formData={formData}
                            handleSessionIdChange={handleSessionIdChange}
                            sessionId={sessionId}
                            connect={connect}
                            isConnected={isConnected}
                            responseCounts={responseCounts}
                        />
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}

export default FrameFileToCore;