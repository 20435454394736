import React from 'react';
import PropTypes from 'prop-types';

const FPSBars = ({ fpsArray }) => {
    if (fpsArray.length >10) fpsArray = fpsArray.slice(-10);
    const maxFPS = Math.max(...fpsArray);

    return (
        <div className="fps-bars">
            {fpsArray.map((fps, index) => (
                <div
                    key={index}
                    className="fps-bar"
                    style={{ height: `${(fps / maxFPS) * 100}%` }}
                >
                    {fps}
                </div>
            ))}
        </div>
    );
};

FPSBars.propTypes = {
    fpsArray: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FPSBars;
