import React, {useEffect, useState} from 'react';

const MoveAiVisualizer = ({formData}) => {
    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
        if (isPlaying) {
            visualize();
        }
    }, [isPlaying]);

    async function visualize() {
        let frames = formData?.res?.inputFrames ?? formData?.inputFrames;
        for (let key in frames) {
            await drawFrame(frames[key]);
            await new Promise((r) => setTimeout(r, (1 / 30) * 1000));
            if (!isPlaying) {
                break;
            }
        }
    }

    function drawFrame(joints) {
        const c = document.getElementById("canv");
        if (!c) {
            return;
        }
        const ctx = c.getContext("2d");
        const canvasWidthSize = 700;
        const canvasHeightSize = 700;

        // Reset the display
        ctx.clearRect(0, 0, canvasWidthSize, canvasHeightSize);

        // Define bone joints
        const bonesJoints = [
            {edg1: "R_ANKLE", edg2: "R_KNEE"},
            {edg1: "R_KNEE", edg2: "R_HIP"},
            {edg1: "R_HIP", edg2: "PELV"},
            {edg1: "L_HIP", edg2: "L_KNEE"},
            {edg1: "L_KNEE", edg2: "L_ANKLE"},
            {edg1: "R_SHOULDER", edg2: "R_ELBOW"},
            {edg1: "R_ELBOW", edg2: "R_WRIST"},
            {edg1: "L_SHOULDER", edg2: "L_ELBOW"},
            {edg1: "L_ELBOW", edg2: "L_WRIST"},
            {edg1: "R_HIP", edg2: "L_HIP"},
            {edg1: "R_SHOULDER", edg2: "L_SHOULDER"},
            {edg1: "R_SHOULDER", edg2: "R_HIP"},
            {edg1: "L_SHOULDER", edg2: "L_HIP"},
        ];

        // Draw bones
        for (let s = 0; s < bonesJoints.length; s++) {
            const joint1 = joints[bonesJoints[s].edg1];
            const joint2 = joints[bonesJoints[s].edg2];

            if (joint1.X <= 0 || joint1.Y <= 0 || joint2.X <= 0 || joint2.Y <= 0) {
                continue;
            }

            ctx.strokeStyle = '#CCF381';
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(joint1.X * canvasWidthSize, joint1.Y * canvasHeightSize);
            ctx.lineTo(joint2.X * canvasWidthSize, joint2.Y * canvasHeightSize);
            ctx.closePath()
            ctx.stroke();
        }

        // Draw joints
        for (const jointName in joints) {
            const joint = joints[jointName];

            if (joint.X <= 0 || joint.Y <= 0) {
                continue;
            }

            let color = '#4831D4';
            if (jointName.includes('ANKLE') || jointName.includes('WRIST')) {
                color = jointName.startsWith('R') ? '#ff873c' : '#00ffcc';
            }

            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.arc(joint.X * canvasWidthSize, joint.Y * canvasHeightSize, 5, 0, 2 * Math.PI);
            ctx.fill();
        }
    }

    const handleTogglePlay = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    return (
        <>
            {/*<button onClick={handleTogglePlay}>{isPlaying ? 'Pause' : 'Play'}</button>*/}
            <canvas style={{backgroundColor: "black"}} width={700} height={700} id="canv"/>
        </>
    );
};

export default MoveAiVisualizer;