import React from 'react';
import "./inspector.css";
const MessagesBox = ({ messages, curFrameIdx, curMoveAiFrameIdx }) => {
    // Filter messages with frame reference greater than curFrameIdx
    const filteredMessages = messages.filter(message => {
        if (message.messageType === "REP_DETECTION"){
            return parseInt(message.frameReference) <= curMoveAiFrameIdx
        }
       return parseInt(message.frameReference) <= curFrameIdx
    });
    filteredMessages.sort((a, b) => parseInt(b.frameReference) - parseInt(a.frameReference));
    return (
        <>
            <div className="messages-box">
                <div className="box-header">
                    <h3>Messages</h3>
                </div>
                <div className={"box-content"}>
                    {filteredMessages.map((message, index) => (
                        <div className={index === 0 ? "last-single-msg" : "single-msg"} key={index} title={message.messageType}>
                            {message.message}
                            <div className={"frame-reference"}>
                                {message.frameReference}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MessagesBox;
