import React, {useState} from 'react';

const MaasMessages = ({userAngle, lastMessage}) => {
    userAngle = JSON.stringify(userAngle) || "null"
    return (
        <div className={"maas-messages"}>
            <div className={"maas-message"}>
                <div className={"maas-message-title"}>User Angle</div>
                <div className={"maas-message-content"}>{userAngle}</div>
            </div>
            <div className={"maas-message"}>
                <div className={"maas-message-title"}>Last Message</div>
                <div className={"maas-message-content"}>{lastMessage}</div>
            </div>
        </div>
    )
}
export default MaasMessages;