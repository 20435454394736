import {getAuth} from 'firebase/auth';

export default {
    firebaseConfigProd: {
        apiKey: 'AIzaSyAEis-TUFwY1uMrD6Y_82K8R37QraQqi6o',
        authDomain: 'wizecare-2021.firebaseapp.com',
        databaseURL: 'https://wizecare-2021-default-rtdb.firebaseio.com',
        projectId: 'wizecare-2021',
        storageBucket: 'wizecare-2021.appspot.com',
        messagingSenderId: '438684500035',
        appId: '1:438684500035:web:e8f0a58d563fc44b3fd4f6',
        measurementId: 'G-G5NDDES33G',
    },

     firebaseConfig : {
        apiKey: 'AIzaSyCFc72HYTKMohK41jENVl3_YRMs1_bVJ3I',
        authDomain: 'wize-development-342714.firebaseapp.com',
        projectId: 'wize-development-342714',
        storageBucket: 'wize-development-342714.appspot.com',
        messagingSenderId: '504313099571',
        appId: '1:504313099571:web:9d7ad203434182948e3a46',
    },


     getIdToken : async function () {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            try {
                const payload = await user.getIdToken();
                return payload;
            } catch (err) {
                throw err;
            }
        } else {
            return Promise.resolve('notLoggedIn');
        }
    },

}
