import React, {useEffect, useRef, useState} from 'react';
import CanvasDots from "../drawing/CanvasDots";
import '../../App.css'
import DataModal from "../Main/DataModal";
import {useOTSession} from "./useOTSession";
import resources from "../tools/resources";
import OT from "@opentok/client";
import MaasMessages from "./MaasMessages";

const VideoWithMaas = (formData) => {
    let apiKey = resources.OT_API_KEY;
    const [isFlipped, setIsFlipped] = useState(true);
    const {getResponseCounts, signals, lastMessage, userAngle}  = useOTSession(formData.formData.OTdata.sessionId);
    const videoRef = useRef(null);
    const videoUrl = formData?.formData?.videoUrl;
    const [session, setSession] = useState(null);
    const [hasStarted, setHasStarted] = useState(false); // New state to track if video has started

    function handleError(error) {
        if (error) {
            console.error(error);
        }
    }

    function initializeSession() {
        const videoEl = videoRef.current;
        const stream = videoEl.captureStream();
        const session = OT.initSession(apiKey, formData.formData.OTdata.sessionId);
        setSession(session);
        let publisher;

        // Subscribe to a newly created stream
        session.on('streamCreated', (event) => {
            session.subscribe(event.stream, videoEl, {}, handleError);
        });

        session.on('sessionDisconnected', (event) => {
            console.log('You were disconnected from the session.', event.reason);
        });

        function publish() {
            const audioTracks = stream.getAudioTracks();
            const videoTracks = stream.getVideoTracks();

            const publisherOptions = {
                videoSource: videoTracks[0],
                audioSource: audioTracks[0],
                fitMode: 'contain',
                width: formData?.formData?.videoResolution.Width,
                height: formData?.formData?.videoResolution.Height,
                mirror: formData?.formData?.isMirror,
                frameRate: formData?.formData?.frameRate,
                playbackRate: formData?.formData?.speed,
            };

            if (!publisher && videoTracks.length > 0 && audioTracks.length >= 0) {
                stream.removeEventListener('addtrack', publish);
                publisher = OT.initPublisher(videoEl, publisherOptions, (err) => {
                    if (err) {
                        videoEl.pause();
                        handleError(err);
                    } else {
                        // Connect to the session
                        session.connect(formData.formData.OTdata.token, (error) => {
                            if (error) {
                                handleError(error);
                            } else {
                                // If the connection is successful, publish the publisher to the session
                                session.publish(publisher, handleError);
                            }
                        });
                    }
                });

                publisher.on('destroyed', () => {
                    videoEl.pause();
                });
            }
        }

        stream.addEventListener('addtrack', publish);
        publish();
    }

    // Play video only on the first signal received
    useEffect(() => {
        if (!hasStarted && signals.length > 0) { // Checks if video has started and signals are received
            const videoEl = videoRef.current;
            videoEl.play();
            setHasStarted(true); // Set the flag to true to prevent multiple plays
        }
    }, [signals, hasStarted]);

    useEffect(() => {}, [lastMessage, userAngle]);

    useEffect(() => {
        const videoEl = videoRef.current;
        if (!videoEl) {
            alert('Could not find video element with id "video" in the DOM.');
        } else {
            videoEl.addEventListener('loadedmetadata', () => {
                initializeSession();
            });
        }
    }, []);

    function terminateSession() {
        session.signal({
            type: 'msg',
            data: "SIGTERM"
        }, (error) => error && console.error("openTokSession.signal issue", error));
    }

    return (
        <>
            <MaasMessages userAngle={userAngle} lastMessage={lastMessage}/>
            <div className={"vid-and-canvas"}>
                <video
                    id="video"
                    className="publisher"
                    ref={videoRef}
                    src={videoUrl}
                    muted
                    controls
                    type="video/mp4"
                    loop
                    crossOrigin="anonymous"
                ></video>

                {signals.length > 0 && (
                    <CanvasDots dots={signals} isFlipped={isFlipped}/>
                )}
            </div>
            <button
                className="terminate-button"
                onClick={terminateSession}
            >
                Terminate Session
            </button>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", position: "absolute", right: 0, top: "60px", width: "auto", height: "40px" }}>
                <p className="good-rep-p">Excellent reps: {getResponseCounts().excellent}</p>
                <p className="bad-rep-p">Good reps: {getResponseCounts().good}</p>
                <DataModal sessionId={formData?.formData?.moveAiSessionId}/>
            </div>
        </>
    );
};

export default VideoWithMaas;
