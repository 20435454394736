import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import '../src/styles/index.css';

ReactDOM.render(
    <React.StrictMode>
        {/*<CssBaseline />*/}
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);
