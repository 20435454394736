import React, {useEffect, useRef, useState} from "react";
// components
import {makeStyles} from "@material-ui/core";
import {
    CircularProgress,
    createTheme,
    ThemeProvider
} from "@mui/material";
import {green} from '@material-ui/core/colors';
import utils from "../tools/utils";
import {useWebSocket} from "../tools/useWebScokets";
import LabTools from "../tools/LabTools";
import '@mediapipe/control_utils/control_utils.css'
import './style.css'
import {
    PoseLandmarker,
    FilesetResolver,
    DrawingUtils
} from "@mediapipe/tasks-vision";
import FPSBars from "./FPSBars";
const useStyles = makeStyles((theme) => ({
    main: {
        opacity: (loader) => (loader ? 0 : 1),
        transition: "0.5s",
    },
    loader: {
        margin: "64px auto",
    },
    loaderContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
    },
    buttonGroup: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: "10px",
    },
    greenButton: {
        margin: "10px !important",
    }
}));

const theme = createTheme({
    palette: {
        primary: green,
    },
});

function LocalMp(formData) {
    const {ws, isConnected, connect, handleSessionIdChange, sessionId, responseCounts} = useWebSocket();
    const frameNumberRef = useRef(0);
    const framesSentRef = useRef(0); // New ref to store the number of frames sent
    const [loader, setLoader] = useState(true);
    const [fpsValues, setFpsValues] = useState([]);
    const classes = useStyles(loader);
    let lastVideoTime = -1;
    let videoElement, canvasElement, canvasCtx, drawingUtils, poseLandmarker, frameInQueue;
    const createPoseLandmarker = async () => {
        const vision = await FilesetResolver.forVisionTasks(
            "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
        );
        poseLandmarker = await PoseLandmarker.createFromOptions(vision, {
            baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
                delegate: "GPU",
            },
            runningMode: "VIDEO",
            numPoses: 2,
            minPoseDetectionConfidence: 0.9,
        });
    };

    async function sendToPose() {
        let startTimeMs = performance.now();
        lastVideoTime = videoElement.currentTime;
        poseLandmarker.detectForVideo(videoElement, startTimeMs, (result) => {
            if (result?.landmarks[0] && result?.landmarks[0].length > 0) {
                frameInQueue = utils.reorderArrayFunc(utils.normalizeAspectRatioFunc(result?.landmarks[0], videoElement.videoWidth, videoElement.videoHeight), 0.5);
                canvasCtx.save();
                canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                for (const landmark of result.landmarks) {
                    drawingUtils.drawLandmarks(landmark, {
                        radius: (data) => DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1)
                    });
                    drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS);
                }
                canvasCtx.restore();
            }
        });

        window.requestAnimationFrame(sendToPose);
    }

    useEffect(async () => {
        videoElement = document.getElementsByClassName('input_video')[0];
        canvasElement = document.getElementsByClassName('output_canvas')[0];

        canvasCtx = canvasElement.getContext('2d');
        drawingUtils = new DrawingUtils(canvasCtx);

        await createPoseLandmarker();

        if (formData?.formData?.media === 'liveCamera'){
            navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
                videoElement.srcObject = stream;
            });
        }else {
            videoElement.src = formData?.formData?.videoUrl;
        }
        videoElement.play()
        setLoader(false)
        videoElement.addEventListener("loadeddata", sendToPose);
        connect(formData?.formData?.moveAiSessionId);
        startInterval()

    }, []);

    const startInterval = () => {
        setInterval(() => {
            sendFrameToCore();
        }, 33.33);

        setInterval(() => {
            console.log('frame count per second:', framesSentRef.current);
            setFpsValues(prevFpsValues => [...prevFpsValues, framesSentRef.current]);
            framesSentRef.current = 0;
        }, 1000);
    }

    const sendFrameToCore = () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN && frameInQueue) {
            frameNumberRef.current += 1;
            framesSentRef.current += 1;
            let transformedLandmarks = frameInQueue + "," + frameNumberRef.current;
            ws.current.send(
                JSON.stringify({
                    action: "saveMessageFrame",
                    data: {sessionId: `${formData?.formData?.moveAiSessionId}`, frames: transformedLandmarks},
                })
            );
        }
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                {loader && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress className={classes.loader} size={300}/>
                    </div>
                )}
                <div className={"video-wrapper"}>
                    <FPSBars fpsArray={fpsValues}/>
                    <div className="video-container">
                        <canvas className="output_canvas" height={1080} width={1920}></canvas>
                        <video className="input_video" crossOrigin="anonymous"></video>
                    </div>
                </div>
                <div className={classes.main}>

                </div>
                <div>
                    <div className={classes.buttonGroup}>
                        <LabTools
                            formData={formData}
                            handleSessionIdChange={handleSessionIdChange}
                            sessionId={sessionId}
                            connect={connect}
                            isConnected={isConnected}
                            responseCounts={responseCounts}
                        />
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}

export default LocalMp;