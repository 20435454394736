import OT from '@opentok/client';
import utils from '../tools/utils';
import config from '../tools/resources';
import {useEffect, useRef, useState} from "react";

const apiKey = config.OT_API_KEY;
let session;
const responseCounts = {good: 0, excellent: 0};
let normalizedSignals = [];
let startTime
let endTime

export function useOTSession(sessionId) {
    const [signals, setSignals] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [userPositioningInstructions, setUserPositioningInstructions] = useState([])
    const [lastMessage, setLastMessage] = useState('')
    const [userAngle, setUserAngle] = useState(null)

    useEffect(() => {
        if (!mounted) {
            setMounted(true)
        } else {
            session = OT.initSession(apiKey, sessionId);
            session.on('signal', handleSignal);
        }
    }, [mounted])

    function disconnectSession() {
        if (session) {
            session.disconnect();
        }
    }

    function getResponseCounts() {
        return responseCounts;
    }

    function startClock(time) {
        startTime =  time
    }

    function stopClock(time) {
        endTime =  time
        calculateElapsedTime();
    }

    function calculateElapsedTime() {
        if (startTime && endTime) {
            const elapsedTime = endTime - startTime;
            // console.log(`Elapsed time: ${elapsedTime} milliseconds.`);
        } else {
            // console.log('Clock not started or stopped.');
        }
    }
    function handleSignal(event) {
        const input = event.data;
        const parsedInput = JSON.parse(input);
        const {msgType, content} = parsedInput;

        if (msgType === 'POSITIONING' || msgType === 'CORRECTION') {
            setLastMessage(content.message);

        } else if (msgType === 'REP_DETECTION') {
            if (content?.message === 'Excellent rep') {
                incrementResponseCount('excellent');
            }
            if (content?.message === 'Good rep') {
                incrementResponseCount('good');
            }
        } else if (msgType === 'FRAME') {
            if (content?.landmarks !== undefined && content?.landmarks !== null && Object.keys(content?.landmarks).length > 0) {
                normalizedSignals = Object.values(content?.landmarks)
                setSignals(normalizedSignals);
            }
            setUserAngle(content?.positioning_state?.userAngle)
        }
    }

    function incrementResponseCount(type) {
        if (type === 'good') {
            responseCounts.good += 1;
        }
        if (type === 'excellent') {
            responseCounts.excellent += 1;
        }
    }

    return {
        disconnectSession,
        getResponseCounts,
        signals,
        userPositioningInstructions,
        startClock,
        stopClock,
        calculateElapsedTime,
        lastMessage,
        userAngle
    };
}
