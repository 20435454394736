import React from 'react';
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis} from 'recharts';

class GraphsSection extends React.Component {
    constructor(props) {
        super(props);
        const keys = Object.keys(this.props.timingData);
        const newKeys = keys.map((str) => {
            let withoutCamelCase = str.replace(/([a-z])([A-Z])/g, '$1 $2');
            for (const word of ["dealing", "send"]) {
                str = withoutCamelCase.replace(new RegExp(`\\b${word}\\b`, 'gi'), '');
            }
            return str;
        });
        this.state = {
            data: [],
            keys: newKeys,
            keyVisibility: {},
        };
    }

    componentDidMount() {
        const arrays = Object.values(this.props.timingData);
        if (!arrays.length) return;
        const numOfFrames = arrays[0].length ;

        const histogram = {};

        arrays.forEach((arr, index) => {
            arr.forEach(val => {
                const bin =  val > 140 ? "140+" :  Math.floor(val / 5) * 5;

                if (!histogram[bin]) histogram[bin] = Array(arrays.length).fill(0);
                histogram[bin][index]++;
            });
        });

        const data = Object.entries(histogram).map(([bin, counts]) => {
            const obj = { bin };
            counts.forEach((count, i) => {
                obj[this.state.keys[i]] = Math.round((count / numOfFrames) * 100) ;
            });
            return obj;
        });
        const keyVisibility = {};
        this.state.keys.forEach((key) => {
            keyVisibility[key] = true;
        });
        this.setState({ data, keyVisibility });
    }
    toggleKeyVisibility = (key) => {
        this.setState((prevState) => ({
            keyVisibility: {
                ...prevState.keyVisibility,
                [key]: !prevState.keyVisibility[key],
            },
        }));
    };
    getColorByIndex = (index) => [
        '#8884d8',
        '#82ca9d',
        '#ffc658',
        '#fd6b19',
        '#a4de6c',
        '#e64c4c',
        '#64b5f6',
    ][index % 6];
    render() {
        const { data, keys, keyVisibility } = this.state;
        const visibleKeys = keys.filter((key) => this.state.keyVisibility[key]);

        return (
            <>
                <div className={"graphs-section"}>
                    <iframe
                        className="session-graph"
                        width="100%"
                        height="100%"
                        src={`https://lookerstudio.google.com/embed/reporting/85a3a5da-252e-42af-8a96-1c100f131ba9/page/uubkC?params=%7B%22ds0.session_id%22:%22${this.props.moveAiSessionIds}%22%7D`}
                        allowFullScreen
                        sandbox="allow-same-origin allow-scripts"

                    />
                    <div className={"histogram-wrapper"}>
                        <BarChart width={450} height={250} data={data}>
                            <CartesianGrid strokeDasharray="2 2" vertical={false}/>
                            <XAxis dataKey="bin"/>
                            <YAxis tickFormatter={(val) => val + '%'}/>
                            <Tooltip formatter={(value, name) => [`${value}%`, name]}/>
                            {keys.map((key, i) => {
                                // Only render the Bar component if the key is visible
                                if (keyVisibility[key]) {
                                    return (
                                        <Bar
                                            key={key}
                                            dataKey={key}
                                            fill={[
                                                '#8884d8',
                                                '#82ca9d',
                                                '#ffc658',
                                                '#fd6b19',
                                                '#a4de6c',
                                                '#e64c4c',
                                                '#64b5f6',
                                            ][i % 6]}
                                            barSize={20}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </BarChart>
                        <div className={"checkbox-wrapper"}>
                            {keys.map((key, index) => (
                                <label key={key} style={{color: this.getColorByIndex(index)}}>
                                    <input
                                        type="checkbox"
                                        checked={visibleKeys.includes(key)}
                                        onChange={() => this.toggleKeyVisibility(key)}
                                    />
                                    {key}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GraphsSection;