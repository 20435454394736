import React from 'react';
import "./inspector.css";
const PositioningBox = ({ positioning}) => {
    delete positioning.isFullBodyPresent;
    const updatedObject = {};
    const map = {
        isUserPresent: "IsPresent",
        userAngle: "Angle",
        userPosture: "Posture",
        userPosition: "Position",
    }
    for (const key in positioning) {
        if (key in map) {
            updatedObject[map[key]] = positioning[key];
        } else {
            updatedObject[key] = positioning[key];
        }
    }
    positioning = updatedObject;
    return (
        <>
            <div className="positioning-box">
                <div className="box-header">
                    <h3>Positioning state</h3>
                </div>

                <div className={"positioning-keys-wrapper"}>
                    {Object.keys(positioning).map((key) => (
                        <div className={"positioning-key"} key={key}>
                            <div className={"positioning-title"}>
                                <span className="positioning-title-key">{key}:</span> <span className="positioning-value">{(positioning[key][0] || "").toString()}</span>
                            </div>
                            <div className={"last-values"}>
                                {positioning[key].join(', ')}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PositioningBox;
