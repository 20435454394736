import React from 'react';
import "./inspector.css";
const SingleWorkflowStep = ({ stepName, stepData, isMarkedDone , isCritical, timeFromPrevious }) => {
    const getIcon = () => {
        if (isMarkedDone) {
            return (
                <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10.5" cy="10" r="9" fill="#41BEB3FF" />
                    <path d="M 7.472 13.229 L 5.497 11.254 L 4.814 11.929 L 7.472 14.588 L 15.885 5.94 L 15.344 5.328 L 7.472 13.229 Z" fill="white"></path>
                </svg>


            );
        } else {
            return (
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7144 0C5.19436 0 0.714355 4.48 0.714355 10C0.714355 15.52 5.19436 20 10.7144 20C16.2344 20 20.7144 15.52 20.7144 10C20.7144 4.48 16.2344 0 10.7144 0ZM11.7144 15H9.71436V13H11.7144V15ZM11.7144 11H9.71436V5H11.7144V11Z" fill={isCritical ? "#FFA500" : "#D32F2F"} />
                </svg>
            );
        }
    };



    const isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    const prettyPrintJSON = (jsonString) => {
        return JSON.stringify(JSON.parse(jsonString), null, 2);
    };
    if (timeFromPrevious >=0) {
        timeFromPrevious = (timeFromPrevious / 1000).toFixed(1)
    } else {
        timeFromPrevious = ""
    }
    return (
            <div
                className={"singleStep"}
                title={isJSON(stepData) ? prettyPrintJSON(stepData) : stepData}
            >
                {getIcon()} {stepName}
                <div className={"timeFromPrevious"}>{timeFromPrevious}</div>
            </div>
    );
};

export default SingleWorkflowStep;
