import React, {useEffect, useRef, useState} from 'react';
import CanvasDots from "../drawing/CanvasDots";
import '../../App.css'
import DataModal from "../Main/DataModal";
import {useOTSession} from "./useOTSession";
import OT from "@opentok/client";
import resources from "../tools/resources";
import MaasMessages from "./MaasMessages";
import Button from '@mui/material/Button';

const CamWithMaas = (formData) => {

    const {getResponseCounts, signals, lastMessage, userAngle} = useOTSession(formData.formData.OTdata.sessionId)
    const [session, setSession] = useState(null)
    const [isMounted, setIsMounted] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {},[signals, lastMessage, userAngle])

    useEffect(() => {
        if (!isMounted){
            setIsMounted(true)
        }else {
            const data = formData.formData;
            const session = OT.initSession(resources.OT_API_KEY, data.OTdata.sessionId);
            setSession(session)
            session.connect(data.OTdata.token, (err) => {
                if (err) {
                    console.error(err);
                } else {
                    const publisher = OT.initPublisher(videoRef.current, {
                        audioSource: false,
                        frameRate: data?.frameRate,
                        width: data?.videoResolution.Width,
                        height: data?.videoResolution.Height,
                        mirror: data.isMirror,
                    });
                    session.publish(publisher);
                }
            });

            return () => {
                session.disconnect();
            };
        }


    }, [isMounted]);

    function terminateSession() {
        session.signal({
            type: 'msg',
            data: "SIGTERM"
        }, (error) => error && console.error("openTokSession.signal issue", error))
    }

    return (
        <div style={{ display: "flex", flexDirection: "column"}}>
            <MaasMessages userAngle={userAngle} lastMessage={lastMessage}/>
            <div className={"vid-and-canvas"}>
                <div className="publisher" ref={videoRef}></div>
                {signals?.length && (
                    <CanvasDots dots={signals}/>
                )}
            </div>
            <Button
                variant="contained"
                className="terminate-button"
                onClick={terminateSession}
            >
                Terminate Session
            </Button>
            <div style={{ display: "flex", alignItems: "center", gap: "10px", position: "absolute", right: 0, top: "60px", width: "auto", height: "40px" }}>
                <p className="good-rep-p">Excellent responses: {getResponseCounts().excellent}</p>
                <p className="bad-rep-p">Good responses: {getResponseCounts().good}</p>
                <DataModal sessionId={formData?.moveAiSessionId}/>
            </div>
        </div>
    );
};

export default CamWithMaas;