import React, {useEffect } from 'react';

function SessionGraph(sessionId) {
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Update sessionId variable here with new data
        }, 5000); // Refresh every 5 seconds

        return () => clearInterval(intervalId);
    }, []); // Only run once when component is mounted

    return (
        <div>
            <iframe
                id="session-graph"
                width="1100"
                height="700"
                // style={{
                // //     position: 'fixed',
                //     right: '40%',
                // //     transform: 'translateX(-50%)',
                // //     bottom: 0,
                // //     margin:"50px",
                // }}
                src={`https://lookerstudio.google.com/embed/reporting/85a3a5da-252e-42af-8a96-1c100f131ba9/page/uubkC?params=%7B%22ds0.session_id%22:%22${sessionId.sessionId}%22%7D`}
                allowFullScreen
                sandbox="allow-same-origin allow-scripts"
            />
        </div>
    );
}

export default SessionGraph
