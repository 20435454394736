import React, {useEffect, useRef, useState} from 'react';
import "./inspector.css";
import MessagesBox from "./messagesBox";
import AnglesBox from "./anglesBox";
import PositioningBox from "./positioningBox";
import {NextButton, PauseButton, PlayButton, PreviousButton} from "./controlsSVGs";
import CircularProgress from '@mui/material/CircularProgress';
import CanvasDots from "../drawing/CanvasDots";
import JSZip from 'jszip';

const FramesSection = ({framesData, traceId, zipUrl}) => {
    let framesArr = framesData.frames;
    framesArr.sort((a, b) => {
        if (a.meta?.frameNumber === b.meta?.frameNumber){
            return a.meta.moveAiFrameNum - b.meta.moveAiFrameNum
        }
        return a.meta?.frameNumber - b.meta?.frameNumber;
    });
    const maxMoveAiFrameNumber = framesArr.length;
    const maxFrameNumber = framesArr[maxMoveAiFrameNumber-1]?.meta?.frameNumber;

    const [frameIndex, orgSetFrameIndex] = useState(1);
    const [isPlaying, setIsPlaying] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [isImagesLoaded, setIsImagesLoaded] = useState(false);
    const [images, setImages] = useState({});

    const dragCircleRef = useRef(null);
    const progressBarRef = useRef(null);

    const setFrameIndex = (newFrameNumber) => {
        if (newFrameNumber < 1) {
            newFrameNumber = 1;
        } else if (newFrameNumber > maxMoveAiFrameNumber) {
            newFrameNumber = maxMoveAiFrameNumber;
        }
        orgSetFrameIndex(newFrameNumber);
    }
    const getLast10PositioningStates = (dataArray) => {
        if (!dataArray || !dataArray.length) return
        const last10PositioningFrames = {
            isUserPresent: [],
            userAngle: [],
            userPosture: [],
            userPosition: [],
        };

        const propertiesToExtract = ["isUserPresent", "userAngle", "userPosture", "userPosition"];

        //by "actual" I mean the frameNumber that is not moveAiFrameNumber
        const currentActualFrameNumber = dataArray[frameIndex]?.meta?.frameNumber;
        let i = 1;
        let lastActualFrameNumberExtracted
        while (true) {

            if (frameIndex - i < 0) {
                break;
            }
            const obj = dataArray[frameIndex - i];
            const iterateFrameNumber = obj.meta.frameNumber;
            if (iterateFrameNumber < currentActualFrameNumber-10) {
                break;
            }
            if (iterateFrameNumber === lastActualFrameNumberExtracted) {
                i++;
                continue;
            }
            lastActualFrameNumberExtracted = iterateFrameNumber;
            propertiesToExtract.forEach(property => {
                last10PositioningFrames[property].push(obj["positioning_state"][property]);
            });
            i++;
        }

        return last10PositioningFrames;
    };
    const getMinuteDifference = (timestamp1, timestamp2) => {
        const diffInMillis = Math.abs(timestamp1 - timestamp2);
        const minutes = Math.floor(diffInMillis / (1000 * 60));
        const seconds = Math.floor((diffInMillis % (1000 * 60)) / 1000);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };
    const handleNextImage = () => {
        setIsPlaying(false);
        setFrameIndex(frameIndex + 1);
    };
    const handlePreviousImage = () => {
        setIsPlaying(false);
        setFrameIndex(frameIndex - 1);
    };

    const handleProgressBarClick = (e) => {
        const rect = progressBarRef.current.getBoundingClientRect();
        const relativeX = e.clientX - rect.left;

        // Calculate the progressPercent and the frame number
        let progressPercent = (relativeX / rect.width) * 100;
        let frameIndex = Math.ceil((progressPercent / 100) * maxMoveAiFrameNumber);

        frameIndex = Math.max(1, Math.min(maxMoveAiFrameNumber, frameIndex));

        // Update the frame number
        setFrameIndex(frameIndex);
    };

    const fetchAndExtractZip = async (traceId, zipUrl) => {

        const response = await fetch(zipUrl);
        const zipBlob = await response.blob();
        const zip = await JSZip.loadAsync(zipBlob);

        // Store the images for use in the render
        const images = {};
        for (const [relativePath, file] of Object.entries(zip.files)) {
            const imageBlob = await file.async('blob');
            const imageUrl = URL.createObjectURL(imageBlob);
            const frameNumber = parseInt(relativePath.split('/').pop().split('.')[0]);
            images[frameNumber] = imageUrl;
        }

        return images;
    }


    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(() => {
                setFrameIndex(frameIndex + 1);
            }, 1000 / 30);
        }
        return () => clearInterval(interval);
    }, [isPlaying, frameIndex]);

    useEffect(() => {
        const progressPercent = ((frameIndex - 1) / (maxMoveAiFrameNumber - 1)) * 100;

        // Set the position of the circle
        dragCircleRef.current.style.left = `${progressPercent}%`;
    }, [frameIndex, isImagesLoaded]);


    useEffect(() => {
        const loadImages = async () => {
            const extractedImages = await fetchAndExtractZip(traceId, zipUrl);
            setImages(extractedImages);
        };

        loadImages().then(r => setIsImagesLoaded(true))
            .catch(e => setIsImagesLoaded(true));


    }, [zipUrl]);

    useEffect(() => {

        const handleMouseMove = (e) => {
            if (dragging) {
                const rect = progressBarRef.current.getBoundingClientRect();
                const relativeX = e.clientX - rect.left;

                // Calculate the progressPercent and the frame number
                let progressPercent = (relativeX / rect.width) * 100;
                let frameIndex = Math.ceil((progressPercent / 100) * maxMoveAiFrameNumber);

                // Ensure the progressPercent and frame number stay within bounds
                progressPercent = Math.max(0, Math.min(100, progressPercent));
                frameIndex = Math.max(1, Math.min(maxMoveAiFrameNumber, frameIndex));

                // Set the position of the circle and update the frame number
                dragCircleRef.current.style.left = `${progressPercent}%`;
                setFrameIndex(frameIndex);
            }
        };

        dragCircleRef.current.addEventListener('mousedown', (e) => {
            setDragging(true);
            dragCircleRef.current.style.cursor = 'grabbing';
        });

        document.addEventListener('mouseup', (e) => {
            setDragging(false);
            dragCircleRef.current.style.cursor = 'grab';
        });

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [dragging]);

    const frame = framesArr[frameIndex - 1];
    const hasLandmarks = frame?.landmarks && frame.landmarks.length > 0;
    const last10PositioningStates = getLast10PositioningStates(framesArr) || [];
    const imageUrl = images[frame?.meta?.frameNumber];
    return (
        <>
            <div className={"frames-section"}>
                <AnglesBox
                    frame={frame?.landmarks}
                />
                <div className="image-player-container">
                    <div className={"image-and-landmarks"}>
                        <img
                            className={"frame-image"}
                            src={imageUrl}
                            alt="Image Player"
                        />
                       <CanvasDots dots={frame && framesArr[frameIndex - 1].landmarks} showAngles={false} />
                    </div>

                    <div className="controls-and-progress">

                        <div className={"progress-and-time"}>
                            <div className="time-past">
                                {frame && getMinuteDifference(framesArr[0].meta.dealingFrameToVision, frame?.meta?.dealingFrameToVision)}
                            </div>
                            <div className="progress-bar-wrapper" ref={progressBarRef} onClick={handleProgressBarClick}>
                                <div className={"progress-bar"}>
                                    <div className="progress-line"></div>
                                    <div className="drag-circle" ref={dragCircleRef}></div>
                                </div>
                            </div>
                        </div>
                        <div className={"controls-wrapper"}>
                            <div> MoveAi frame number: &nbsp;{frame && frame.meta.moveAiFrameNum}</div>
                            <div className="controls">
                                <button onClick={handlePreviousImage}>
                                    <PreviousButton/>
                                </button>
                                <button onClick={handlePlayPause}>
                                    {isImagesLoaded ? (
                                        <button onClick={handlePlayPause}>
                                            {isPlaying ? <PauseButton /> : <PlayButton />}
                                        </button>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </button>
                                <button onClick={handleNextImage}>
                                    <NextButton/>
                                </button>
                            </div>
                            <div> Frame number:&nbsp; {frame && frame.meta.frameNumber}</div>
                        </div>
                    </div>
                </div>
                <div className="box-container">
                    <PositioningBox
                        positioning={last10PositioningStates}
                    />
                    <MessagesBox
                        messages={framesData.messages}
                        curFrameIdx={frame && frame.meta.frameNumber}
                        curMoveAiFrameIdx={frame && frame.meta.moveAiFrameNum}
                    />
                </div>
            </div>
        </>
    );
};

export default FramesSection;
