import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Switch} from "react-router"
import {LandingPage} from "./components/Main/LandingPage";
import {ProSidebarProvider} from 'react-pro-sidebar';
import InspectorTop from "./components/inspector/inspectorTop";
import {initializeApp} from "firebase/app";
import firebase from "./components/firebase";
import {getAuth, onAuthStateChanged, signInWithCustomToken} from "firebase/auth";
import React, {useEffect, useState} from "react";
import { isDev } from "./components/tools/resources";

export default function App() {
    function receiveMessage(event) {
        if (event.data.message === "loginSuccess") {
            signInWithCustomToken(auth, event.data.value.customToken);
        }
    }
    const domain = isDev ?  "wizecaredev.com" : "wizecare.com";
    const loginUrl = 'login.' + domain;
    const firebaseConfig = isDev ? firebase.firebaseConfig : firebase.firebaseConfigProd;
    initializeApp(firebaseConfig);

    const auth = getAuth();
    const [currentUser, setCurrentUser] = useState(null);
    window.addEventListener("message", receiveMessage, false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
    }, [auth]);

    return (
        <>
            {
                (currentUser)
                ?
                <Router>
                    <div>
                        <Switch>
                            <Route exact path='/'>
                                <ProSidebarProvider>
                                    <LandingPage/>
                                </ProSidebarProvider>
                            </Route>
                            <Route exact path='/inspector'>
                                <InspectorTop/>
                            </Route>
                        </Switch>
                    </div>
                </Router>
                :
                <div style={{display: 'block', margin: 'auto', width: '405px', height: '662px'}}>
                    <iframe
                        src={`https://${loginUrl}/`}
                        id={'loginAppFrame'}
                        width="100%"
                        height="100%"
                    />
                </div>

            }
        </>
    );
};