import React from 'react';
import SingleWorkflowStep from "./singleWorkflowStep";
import "./inspector.css";

const WorkflowSteps = ({steps}) => {
    const existingStepsNames = steps.map((step) => step.step);
    const allSteps = [
        {
            "stepName": "Puppeteer server called",
            "isCritical": true
        },
        {
            "stepName": "Dealer invoked",
            "isCritical": true
        },
        {
            "stepName": "Connected to OT session",
            "isCritical": true
        },
        {
            "stepName": "Connected to VISION websocket",
            "isCritical": true
        },
        {
            "stepName": "Dealer got the video",
            "isCritical": true
        },
        {
            "stepName": "Got first frame from vision",
            "isCritical": false
        },
        {
            "stepName": "Got first processed frame from core",
            "isCritical": false
        },
        {
            "stepName": "End dealer",
            "isCritical": true
        },
        {
            "stepName": "Puppeteer end MAAS",
            "isCritical": true
        }
    ];
    let isPreviousStepDone = null;
    let existingIndexes = [];
    let processedSteps = allSteps.map((step, index) => {
        let diffInMilliseconds;
        // Check if the step's 'step' key exists in the 'steps' array
        // const isStepDone = steps.some((item) => {
        //     return item.toLowerCase().includes(step.stepName.toLowerCase())
        // })
        const i = existingStepsNames.findIndex((item) => item.toLowerCase().includes(step.stepName.toLowerCase()));
        let stepData= null, timeFromPrevious = null;
        let isStepDone = false;
        if (i !== -1) {
            isStepDone = true;
            stepData= steps[i]?.stepData
            //check if stepData is a stringified JSON
            if (stepData && stepData.includes("exerciseConfig")) stepData = processConfigKeys(stepData);

            if (isPreviousStepDone) {
                const date1 = new Date(steps[i]?.timeSent.value);
                const date2 = new Date( steps[i-1]?.timeSent.value);

                diffInMilliseconds = Math.abs(date1 - date2);
            }

            isPreviousStepDone = true;
        } else {
            isPreviousStepDone = false;
        }

        index = (i >- 1) ? i : index
        if (existingIndexes.includes(index))  index = Math.max(...existingIndexes) + 1
        existingIndexes.push(index)
        return {stepName: step.stepName, isCritical: step.isCritical, stepData, isStepDone, diffInMilliseconds, index}
    });
    processedSteps.sort((a, b) => a.index - b.index);
    return (
        <>
            <div className={"workflowStepsWrapper"}>
                {processedSteps.map((step, index) => {
                    return (
                        <SingleWorkflowStep
                            key={index}
                            stepName={step.stepName}
                            stepData={step.stepData}
                            isMarkedDone={step.isStepDone}
                            isCritical={step.isCritical}
                            timeFromPrevious={step.diffInMilliseconds}
                        />
                    );
                })}

            </div>
        </>
    );
};

function processConfigKeys(stepData) {
    stepData = JSON.parse(stepData)
    delete stepData.OTSessionId
    delete stepData.OTToken
    delete stepData.WizecareAccountId
    delete stepData.breakdownType
    delete stepData.exerciseId
    delete stepData.language
    delete stepData.moveAiSessionIds
    delete stepData.startingPosture
    delete stepData.sessionType
    delete stepData.traceId
    delete stepData.exerciseConfig[0].bad_threshold
    delete stepData.exerciseConfig[0].good_threshold
    delete stepData.exerciseConfig[0].threshold_1
    delete stepData.exerciseConfig[0].threshold_2
    delete stepData.exerciseConfig[0].bias
    return stepData;
}
export default WorkflowSteps;
