import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import LocalMp from "../mediapipe-components/LocalMp";
import CamWithMaas from "../MAAS/CamWithMaas";
import VideoWithMaas from "../MAAS/VideoWithMaas";
import apiCalls from "../tools/apiCalls";
import MoveAiVisualizer from "../drawing/MoveAiVisualizer";
import MainSideBar from "./MainSideBar";
import {CircularProgress} from "@mui/material";
import FrameFileToCore from "../mediapipe-components/FramesFileToCore";

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(5),
    },
    btn: {
        margin: theme.spacing(2),
    },
    loader: {
        margin: "64px auto",
    },
    loaderContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
    },

}));

export const LandingPage = () => {

    const classes = useStyles();
    const [formData, setFormData] = useState(null);
    const [moveAiExercises, setMoveAiExercises] = useState();
    const [moveAiLastSessions, setMoveAiLastSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
                setIsLoading(true);
                await Promise.all([
                    setMoveAiLastSessions(await apiCalls.getLastMoveAiSessions()),
                    setMoveAiExercises(await apiCalls.getExcercises())
                ]);
                setIsLoading(false);
        };
        fetchData();
    }, []);


    const handleSubmit = (data) => {
        setFormData(data);
    };

    return (
        <>
            {isLoading ? (
                <div className={classes.loaderContainer}>
                    <CircularProgress className={classes.loader} size={300}/>{" "}
                </div>
            ) : (
                <div className="container">
                    <div className="sidebar-container">
                        <MainSideBar moveAiLastSessions={moveAiLastSessions} moveAiExercises={moveAiExercises} onSubmit={handleSubmit}/>
                    </div>
                    <div className="main-container">
                        {formData?.arch === 'LOCAL' ? (
                            <LocalMp formData={formData}/>
                        ) : null}
                        {formData?.arch === 'MAAS' && formData?.media === 'liveCamera' ? (
                            <CamWithMaas formData={formData}/>
                        ) : null}
                        {formData?.arch === 'MAAS' && formData?.media !== 'liveCamera' ? (
                            <VideoWithMaas formData={formData}/>
                        ) : null}
                        {formData?.media === 'jsonFile' ? (
                            <FrameFileToCore formData={formData}/>
                        ) : null}
                        {formData?.arch === 'MOVE-AI' ?
                            <MoveAiVisualizer formData={formData}/> : null
                        }
                    </div>
                </div>
            )}
        </>
    );
};

