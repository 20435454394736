import React, {useEffect} from "react";
import * as pose from "@mediapipe/pose";
import videoResulution from "../tools/utils.js";

// styling
import "../../App.css";

const MainCanvas = ({canvasRef, poseLandmarks, img,isFlipped}) => {
    // pose functions that allow me to draw on canvas
    // were added in index.html
    const dConnect = window.drawConnectors;

    useEffect(() => {
        if (poseLandmarks !== undefined) {
            // Setting h , w of canvas
            canvasRef.current.width = videoResulution.videoResolution.Width;
            canvasRef.current.height = videoResulution.videoResolution.Height;
            const canvasElement = canvasRef.current;
            const canvasCtx = canvasElement.getContext("2d");
            try {
                canvasCtx.save();
                canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                canvasCtx.drawImage(
                    img,
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height
                );
                // draw
                if (poseLandmarks.poseLandmarks) {
                    // come to the front
                    canvasCtx.globalCompositeOperation = "source-over";
                    // skeleton
                    // gets canvas, cordinations, body pattern from pose library
                    dConnect(
                        canvasCtx,
                        poseLandmarks.poseLandmarks,
                        pose.POSE_CONNECTIONS,
                        {
                            color: "#1a1515",
                            lineWidth: 2,
                        }
                    );

                    // red dots
                    canvasCtx.fillStyle = "red";
                    for (let i = 0; i < poseLandmarks.poseLandmarks.length; i++) {
                        const landmark = poseLandmarks.poseLandmarks[i];
                        const x = landmark.x * canvasElement.width;
                        const y = landmark.y * canvasElement.height;
                        canvasCtx.beginPath();
                        canvasCtx.arc(x, y, 5, 0, 2 * Math.PI);
                        canvasCtx.fill();
                    }

                    canvasCtx.restore();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [canvasRef, dConnect, img, poseLandmarks]);

    const transformStyle = {
        transform: `translate(-50%, -50%) ${isFlipped ? "scaleX(-1)" : "scaleX(1)"}`,
    };

    return (
        <div>
            <canvas className="canvas" ref={canvasRef} style={transformStyle}></canvas>
        </div>
    );
};

export default MainCanvas;
