import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: theme.spacing(2),
    },
    videoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(2),
    },
    video: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));

const ExerciseVideos = ({onSelect,moveAiExercises}) => {
    const classes = useStyles();
    const [selectedVideo, setSelectedVideo] = useState("");

    const handleVideoChange = (event) => {
        const videoFileName = event.target.value;
        const selectedExercise = moveAiExercises?.find(exercise => exercise.fileName === videoFileName);
        if (selectedExercise) {
            const videoUrl = `https://media.wizecare.com/vtexercisevideos/white/en/${selectedExercise.id}.mp4`;
            setSelectedVideo(videoUrl);
            onSelect(videoUrl,selectedExercise.id);
        }
    };
    const renderThumbnail = (exercise) => {
        const thumbnailUrl = exercise.thumbnail;
        if (!thumbnailUrl) {
            return null;
        }
        return (
            <ListItemIcon>
                <img
                    src={thumbnailUrl}
                    alt={`Exercise video ${exercise.label}`}
                    width={32}
                    height={32}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "path/to/default/image.jpg"; // or e.target.src = "";
                    }}
                />
            </ListItemIcon>
        );
    };
    return (
        <div className={classes.root}>
            <Select
                value={selectedVideo || "select"}
                onChange={handleVideoChange}
                renderValue={(value) =>
                    //TODO value is a url, not an object
                    value !== "select" ? `Exercise ${value.label}` : "Select config"
                }
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 500,
                        },
                    },
                }}
                style={{ width: '100%', backgroundColor: 'rgba(25,117,208,0.4)', borderRadius: '5px', padding: '5px' }}
            >
                <MenuItem value={null}>Select config</MenuItem>
                {moveAiExercises?.map((exercise) => (
                    <MenuItem key={exercise.id} value={exercise.fileName}>
                        {renderThumbnail(exercise)}
                        <ListItemText primary={`${exercise.id}: ${exercise.label}`}/>
                    </MenuItem>
                ))}
            </Select>
            {selectedVideo && (
                <div className={classes.videoContainer} style={{margin: 5}}>
                    <video className={classes.video} src={selectedVideo} controls/>
                </div>
            )}
        </div>
    );
};

export default ExerciseVideos;
